import type { RequestType } from '@/types/og'
import { removeHTMLTags, truncateString } from '@/lib/utils'

export function createOgImageUrl(baseUrl: URL, params: RequestType): string {
  const url = new URL('/api/og', baseUrl)

  Object.entries(params).forEach(([key, value]) => {
    if (value)
      url.searchParams.set(key, value)
  })

  return url.toString()
}

export function createSafeDescription(
  text: string | undefined | null,
  maxLength: number,
  fallback: string,
): string {
  if (maxLength <= 0)
    throw new Error('maxLength must be a positive number')

  if (!text)
    return fallback

  return truncateString(removeHTMLTags(text), maxLength) || fallback
}
